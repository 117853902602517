import { render, staticRenderFns } from "./center.vue?vue&type=template&id=226df5a0"
import script from "./center.vue?vue&type=script&lang=js"
export * from "./center.vue?vue&type=script&lang=js"
import style0 from "./center.vue?vue&type=style&index=0&id=226df5a0&prod&rel=stylesheet%2Fless&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
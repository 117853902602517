import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'user/crm/users',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'user/crm/users/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'user/crm/users',
    method: 'put',
    data
  })
}

export function validPass(pass) {
  return request({
    url: 'user/crm/users/validPass/' + pass,
    method: 'get'
  })
}

export function updatePass(pass) {
  return request({
    url: 'user/crm/users/updatePass/' + pass,
    method: 'get'
  })
}

export function updateEmail(code, data) {
  return request({
    url: 'user/crm/users/updateEmail/' + code,
    method: 'post',
    data
  })
}

export function updateAvatar(params) {
  return request({
    url: 'user/crm/users/updateAvatar',
    method: 'get',
    params: params
  })
}

export function sendPhoneVerCode(params) {
  return request({
    url: 'community/crm/tCustInfo/sendPhoneVerCode',
    method: 'get',
    params: params
  })
}

export function authPhoneVerCode(params) {
  return request({
    url: 'community/crm/tCustInfo/authPhoneVerCode',
    method: 'get',
    params: params
  })
}


export function forgetPwd(params) {
  return request({
    url: '/user/auth/forgetPwd',
    method: 'get',
    params: params,
  })
}

export function resetPwd(params) {
  return request({
    url: '/user/auth/resetPwd',
    method: 'get',
    params: params,
  })
}

export function adminResetPwd(params) {
  return request({
    url: `/user/crm/resetUserPassword/${params.userId}`,
    method: 'put',
  })
}

